import { navigate } from "gatsby";
import React, { useContext } from "react";
import DataLoadingContext from "../context/data-loading-context";

const resetData = (
  setGettingDataFailed,
  setGetPropertyData,
  setGetScenarioData
) => {
  setGettingDataFailed(false);
  setGetPropertyData(true);
  setGetScenarioData(true);
};

const ErrorPageComponent = () => {
  const {
    setGettingDataFailed = () => {},
    setGetPropertyData = () => {},
    setGetScenarioData = () => {},
  } = useContext(DataLoadingContext);

  const handleClickBackToHome = () => {
    resetData(setGettingDataFailed, setGetPropertyData, setGetScenarioData);
    navigate("/");
  };
  return (
    <div className="error-page-container">
      <div className="h1 text-uppercase">Corrupted Content Error</div>
      <div className="h6 mt-3">
        The page you are trying to view cannot be shown because an error in the
        data transmission was detected.
      </div>
      <div
        role="button"
        onKeyPress={() => {}}
        tabIndex="0"
        onClick={() => handleClickBackToHome()}
        className="btn custom-button-fill"
      >
        Back To HomePage
      </div>
    </div>
  );
};

export default ErrorPageComponent;
